<template>
  <!--  内蒙古自治区工伤预防线上培训平台-->
  <LoginMongolia v-if="$judgeServerHostname() == 1"></LoginMongolia>
  <!--  安知企培  -->
  <LoginQiye v-else-if="$judgeServerHostname() == 2"></LoginQiye>
  <!--  安知人力资源服务平台  -->
  <HRLogin v-else-if="$judgeServerHostname() == 3"></HRLogin>
  <!--  安知管理后台  -->
  <LoginCommon v-else></LoginCommon>
</template>

<script>
import LoginCommon from "./LoginCommon.vue";
import LoginMongolia from "./LoginMongolia.vue";
import LoginQiye from "./LoginQiye.vue";
import HRLogin from "../humanResources/HRLogin.vue";
export default {
  name: "login",
  data() {
    return {
      isMongolia: null,
    };
  },
  components: {
    LoginCommon,
    LoginQiye,
    LoginMongolia,
    HRLogin,
  },
  methods: {
    // 获取mac地址
    socketGetMac() {
      var ws = new WebSocket("ws://127.0.0.1:19974");
      ws.onopen = function () {
        ws.send("websocekt测试");
      };
      ws.onmessage = function (e) {
        if (e.data) {
          localStorage.setItem("macAdd", e.data);
        }
      };
    },
  },
  created() {
    this.socketGetMac();
  },
};
</script>
